/* catalogs tree
---------------------------------- */
.catalogs{
    font-size: 0.9rem;
    padding-left: 1rem;
    border-left: 1px solid #eee;

    nav {
        a {
            padding: 2px 5px 2px 5px;
            display: block;
            position: relative;
            color: inherit;
        }
        a.expand:after {
            font-family: "FontAwesome";
            content: "\f077";
            font-size: 0.4rem;
            font-weight: 400;
            position: absolute;
            right: 15px;
            top: 2px;
            color: #909090;
        }

        a.expand-down:after {
            font-family: "FontAwesome";
            content: "\f078";
            font-size: 0.4rem;
            font-weight: 400;
            position: absolute;
            right: 15px;
            top: 2px;
            color: #909090;
        }
        a:hover{
            color: #C59535;
            background-color: #EFEFEF;
        }
        a:not(:hover) {
            text-decoration: none;
        }
        ul {
            padding-left: 0;
            list-style: none;
            /* 1 level */
            li {
                margin-bottom: 0.25rem;

                ul {
                    padding-left: 1rem;
                    margin-top: 0.25rem;
                    li {
                        a {
                            padding: 2px 5px;
                        }
                        :before {
                            font-family: "FontAwesome";
                            content: unset !important;
                            font-size: 0.2rem;
                            font-weight: 400;
                            position: absolute;
                            left: 0px;
                            top: 2px;
                            color: #909090;
                        }
                    }
                }
            }
            li.active a{
                color: #C59535;
            }
            li.active a:after {
                font-family: "FontAwesome";
                content: "\f10c";
                font-size: 12px;
                font-weight: 400;
                position: absolute;
                left: -10px;
                top: 2px;
            }
        }
    }

    nav.maxHeight{
        max-height: calc(100vh/4);
        overflow-y: auto;
    }
}
.catalogs-single{
    height: calc(100vh - 7rem);
    overflow-y: auto;
}
