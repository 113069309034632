/*Index
  =============
  Global style
  Unit style
  Header section
  Search popup
  Offcanvas Header
  Main wrap
  About section
  Counts section
  categories section
  Collection section
  Sutra section
  Footer section
  Bnner wrap
  Classic item
  Sidebar box
  ---------------------------------- */

/* Global style
---------------------------------- */
body{
  background: #fff;
  font-family: -apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft JhengHei,Microsoft YaHei UI,Microsoft YaHei,Source Han Sans CN,sans-serif;
  color: #222222;
  font-size: 16px;
  font-weight: normal;
  line-height: 26px;
  margin: 0;
  padding: 0;
  position: relative;
}
ul li {
    list-style: none;
}
form{
margin:0;
}
a{
  color: #C59535;
  text-decoration: none;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}
a:hover, a:active {
  color: #F8B62D;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  text-decoration: none;
}
a.jump-link{
  margin-left: 10px;
  background-color: #C59535;
  font-size: 10px;
  padding: 5px;
  color: #FFFFFF;
  border-radius: 10px;
}
a.jump-link:hover{
  padding-left: 15px;
  background-color: #FFFFFF !important;
  color: #C59535 !important;
  transition: .3s;
  -moz-transition: .3s;
  -webkit-transition: .3s;
  -o-transition: .3s;
}
section {
  padding: 40px 0;
  overflow: hidden;
  background: #FFFFFF;
}
.gray-wrap{
  background: #F8F7F3;
}
.golden{
  color: #C59535 !important;
}
.icon-16{
  width: 16px;
}
.icon-32{
    font-size: 2rem !important;
  width: 32px;
}
.icon-64{
    font-size: 4rem !important;
    width: 64px;
}
.icon-128{
    font-size: 8rem !important;
    width: 128px;
}
.show{
display: block;
}
.hide{
display: none !important;
}
.list-page {
  padding-bottom: 80px;
  padding-top: 0;
}
.view-page{
  padding-bottom: 50px;
}
.alert{
  margin-bottom: 0px;
}
.uncom-tooltips{
    cursor: pointer;
    background: #c59535;
    color: #FFF;
    padding: 0 3px;
}
.bodhidict-theme {
    border-radius: 5px;
    border-bottom: 3px solid #c59535;
    background: #F8F7F3;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
    color: #222;
}
/* Use this next selector to style things like font-size and line-height: */
.bodhidict-theme .tooltipster-content {
    font-size: 1rem;
    line-height: 2rem;
    padding: 8px 10px;
}

.bodhidict-theme .tooltipster-content p {
    margin-bottom: 0;
}

/* slick style
---------------------------------- */
.slick-arrow {
  position: absolute;
  font-size: 26px;
  font-weight: 100;
  text-align: center;
  background: rgb(0,0,0,.2);
  color: #FFFFFF;
  padding: 28px 10px;
  width: 65px;
  cursor: pointer;
  opacity: 1;
  transition: opacity .3s;
  -moz-transition: opacity .3s;
  -webkit-transition: opacity .3s;
  -o-transition: opacity .3s;
}
.slick-arrow:hover {
  color: #FFFFFF !important;
  background-color: #F8B62D !important;
  transition: opacity .3s;
  -moz-transition: opacity .3s;
  -webkit-transition: opacity .3s;
  -o-transition: opacity .3s;
}
.arrow_left.slick-arrow {
  z-index: 8;
  right: 0;
}
.arrow_right.slick-arrow {
  left: 0;
  z-index: 8;
}

@media only screen and (max-width: 767px){
  .words-wrap .slick-dots, .collection-4slick .slick-arrow {
    display: none !important;
  }
}
@media only screen and (max-width: 992px){
  .words-wrap .slick-dots, .collection-4slick .slick-arrow {
    display: none !important;
  }
}
.slick-dotted.slick-slider{
  margin-bottom: 0;
}
.slick-dots li button:before{
  font-size: 36px;
  color: #C59535 !important;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
  color: #F8B62D !important;
}
.coll-dots{
  bottom: 10px;
}
.coll-dots li button:before{
  font-size: 36px;
  color: #FFFFFF !important;
}
.coll-dots li button:hover,
.coll-dots li button:focus
{
  color: #FFFFFF !important;
}

.words-wrap .slick-arrow {
  background: rgb(0,0,0,0);
  border: 0px;
  padding: 0;
  width: 22px;
  font-size: 22px;
  top: 15px;
  color: #C59535;
  cursor:pointer;
}
.words-wrap .slick-arrow:hover{
  color: #F8B62D !important;
  background: unset !important;
}
.words-wrap .arrow_right.slick-arrow {
  left: unset;
  right: 10px;
}
.words-wrap .arrow_left.slick-arrow {
  margin-right: 0px;
  right: 35px;
  z-index: 8;
}
.words-wrap .slick-dots {
  top: 8px;
  right: 60px;
  bottom: auto;
  width: auto;
}
.collection-4slick .slick-arrow {
  top: -65px;
  background: #C59535;
  padding: 10px 20px;
}
.collection-4slick .slick-arrow:hover {
  color: #FFFFFF !important;
  background: #F8B62D;
}
.collection-4slick .arrow_left.slick-arrow {
  right: 150px;
  border-right: 1px dashed #FFFFFF;
}
.collection-4slick .arrow_right.slick-arrow {
  right: 85px;
  left: unset;
}
.collection-wrap .slick-arrow{
  top: 280px;
  opacity: 0;
}
.collection-wrap:hover .slick-arrow{
  opacity: 1;
  transition: opacity 1s;
  -moz-transition: opacity 1s;
  -webkit-transition: opacity 1s;
  -o-transition: opacity 1s;
}

.images-slick-warp .slick-arrow {
  top: 210px;
  opacity: 0;
  transition: opacity .3s;
  -moz-transition: opacity .3s;
  -webkit-transition: opacity .3s;
  -o-transition: opacity .3s;
}
.images-slick-warp:hover .slick-arrow {
  opacity: 1;
  transition: opacity .3s;
  -moz-transition: opacity .3s;
  -webkit-transition: opacity .3s;
  -o-transition: opacity .3s;
}
.images-slick-warp .slick-dots{
  bottom: 20px;
}

/* Unit style
---------------------------------- */
/* btn more */
.btn-more:before {
  width: 100%;
  height: 0;
  top: 50%;
  left: 50%;
  background: #C59535;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
  content: '';
  position: absolute;
  z-index: -1;
  transition: all 0.3s;
}
.btn-more:hover::before {
  height: 400%;
  opacity: 1;
}
.btn-more:hover {
  color: #fff;
}
.btn-more {
  overflow: hidden;
  position: relative;
  color: #FFFFFF;
  background: #558263;
  border: 0px;
  border-radius: 5px;
  font-size: 1.125rem;
  padding: 0.5rem 1.5rem;
  line-height: 2;
  box-shadow: 0 0 1px transparent;
  text-transform: capitalize;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.btn-more-golden {
  background: #C59535;
}
.pre,.next{
  position: relative;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
  line-height: 1.5;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
  transition: all .3s ease-in-out !important;
  -moz-transition: all .3s ease-in-out !important;
  -webkit-transition: all .3s ease-in-out !important;
  -o-transition: all .3s ease-in-out !important;
}
.next{
    color: #FFFFFF !important;
    padding: 0.75rem 2rem;
    background-color: #C59535;
}
.next:hover {
    color: #FFFFFF;
    background-color: #C59535;
    padding-left: 2.5rem;
    transition: all .3s ease-in-out !important;
    -moz-transition: all .3s ease-in-out !important;
    -webkit-transition: all .3s ease-in-out !important;
    -o-transition: all .3s ease-in-out !important;
}
.pre{
    color: #444444;
    margin-right: 10px;
    padding: 0.75rem 1rem;
    box-shadow: inset 0 0 0 2px #CFD9E0;
    background: none;
}
.pre:hover {
    color: #444444;
    background-color: #f8f8f8;
}

/* loader */
.preloader {
  position: fixed;
  background-color: var(--moniz-black, #1e1e22);
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9991;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
}
.preloader-image {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
/* Scroll to top */
.scroll-to-top {
  -webkit-box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
  background-color: #C59535;
  color: #FFFFFF;
  bottom: 18px;
  position: fixed;
  right: 25px;
  display: none;
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  font-size: 1.5rem;
  line-height: 40px;
  font-weight: 700;
  cursor: pointer;
  opacity: 1;
  z-index: 999;
}
@media only screen and (max-width: 991px) {
  .scroll-to-top {
    right: 12px;
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
}
.scroll-to-top:hover {
  background-color: #F8B62D;
}
.scroll-to-top.show {
  visibility: visible;
  opacity: 0.8;
  bottom: 60px;
}
@media only screen and (max-width: 767px) {
  .scroll-to-top.show {
    bottom: 10px;
  }
}
.scroll-to-top.show:hover {
  opacity: 1;
}

.breakcrumb-section {
  background-color: #f7f7f7!important;
  border-bottom: 1px solid #dadada;
}
.breadcrumb-item {
  color: #959595;
  letter-spacing: 0;
  text-transform: capitalize;
}
.breadcrumb-item+.breadcrumb-item {
    padding-left: 1rem;
}
.breadcrumb-item+.breadcrumb-item::before {
    padding-right: 1rem;
}
.breadcrumb-item.active {
    color: #C59535;
}
.breadcrumb-item a {
    color: inherit;
}
.breadcrumb-item a:hover {
    color: #C59535;
}

/* Header section
---------------------------------- */
.min-nav a {
  font-size: 0.9375rem;
  color: #606060;
}
.min-nav a:hover {
  color: #C59535;
}
.header-area{
  padding: 0;
  z-index: 9;
}
.header-area.transparent{
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.header-area.static{
  position: relative;
  background: #FFFFFF !important;
  -webkit-box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, .1);
}
.unshadow{
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  border-bottom: 1px solid #ececec;
}
.header-area.scrolled {
  position: fixed !important;
  right: 0;
  left: 0;
  top: 0;
  margin-top: -130px;
  background: #FFFFFF !important;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
}
.static .main-menu.nav > li > a,
.scrolled .main-menu.nav > li > a{
  color: #444444;
}
.static .search-toplink a,
.scrolled .search-toplink a{
  color: #326292;
}
.static .search-toplink,
.scrolled .search-toplink{
  border-left: 1px dashed #326292;
}
.scrolled.awake {
  margin-top: 0px;
  -webkit-transition: .3s all ease-out;
  -o-transition: .3s all ease-out;
  transition: .3s all ease-out;
}
.scrolled.sleep {
  -webkit-transition: .3s all ease-out;
  -o-transition: .3s all ease-out;
  transition: .3s all ease-out;
}

/* main menu */
@media only screen and (max-width: 991px) {
  .nav-area {
    display: none;
  }
}
.main-menu.nav > li{
  padding: 0;
  margin-right: 0;
}
.main-menu.nav > li > a{
  font-size: 20px;
  padding:0 10px;
  margin: 0 10px;
  height: 88px;
  line-height: 90px;
  color: #fff;
  font-weight: 400;
  opacity: 1 !important;
}
@media only screen and (max-width: 1199px) {
  .main-menu.nav > li > a {
    height: 78px;
    line-height: 80px;
    margin: 0 7px;
    margin-left: 7px;
    padding: 0 7px;
    padding-left: 7px;
  }
}
.main-menu.nav > li.active > a{
  color: #F8B62D;
}
.main-menu.nav > li > a:hover{
  color: #F8B62D;
}
@media (max-width: 991.98px) {
  .main-menu.nav > li > a:hover{
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}
/* submenu */
.has-submenu {
  padding-right: 0px;
  position: relative;
}
.has-submenu > a {
  position: relative;
}
.has-submenu:hover > .submenu-nav {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
  pointer-events: visible;
}
.header-area .main-menu.nav .has-submenu .submenu-nav {
  -webkit-box-shadow: 0 1px 24px 0 rgb(0 0 0 / 9%);
  box-shadow: 0 1px 24px 0 rgb(0 0 0 / 9%);
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  color: #555;
  padding: 0 0;
  position: absolute;
  width: 200px;
}
.has-submenu .submenu-nav {
  background-color: #FFFFFF;
  border: none;
  border-bottom: none;
  padding: 15px 0 15px;
  position: absolute;
  left: -15px;
  top: 100%;
  opacity: 0;
  min-width: 200px;
  pointer-events: none;
  margin-top: 30px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  visibility: hidden;
  z-index: 9999;
}
@media only screen and (max-width: 1199px) {
  .has-submenu .submenu-nav {
    min-width: 200px;
    left: 0;
  }
}
.has-submenu .submenu-nav:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 100%;
}
.has-submenu .submenu-nav > li {
  border-bottom: 1px solid rgba(173, 181, 189, 0.15);
  padding: 0;
  margin-bottom: 0;
}
.has-submenu .submenu-nav > li a {
  color: #1d1d1d;
  font-weight: 400;
  letter-spacing: inherit;
  text-transform: capitalize;
  display: block;
  font-size: 14px;
  padding: 11px 25px 10px;
  position: relative;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}
.has-submenu .submenu-nav > li.active a {
  color: #F8B62D !important;
}
.has-submenu .submenu-nav > li a:hover {
  color: #FFFFFF;
}
.has-submenu .submenu-nav > li:hover > a {
    color: #F8B62D;
    background-color: rgba(173, 181, 189, 0.15);
}
/* logo */
.logo{
  padding: 20px 0;
}
.logo130{
  width: 130px;
}
.header-logo-area {
  margin-top: 1px;
  position: relative;
}
.header-logo-area a {
  display: block;
}
.header-logo-area img {
  margin: 0 auto;
  width: 130px;
}
@media only screen and (max-width: 1399.98px) {
  .header-logo-area img {
    max-width: 130px;
  }
}
@media only screen and (max-width: 1199px) {
  .header-logo-area img {
    max-width: 112px;
  }
}
@media only screen and (max-width: 991px) {
  .header-action-area {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 70px;
  }
  .logo{
    text-align: center;}
}
/* top search link */
.search-toplink{
  border-left: 1px dashed #fff;
  margin-left: 25px;
}
@media only screen and (max-width: 767px) {
  .search-toplink{
    display: block;
    margin-left: 10px;
  }
}
.search-toplink a{
  padding: 10px 25px;
  font-size: 1.25rem;
  font-weight: 100;
  color: #FFFFFF;
}
.search-toplink a:hover{
  color: #F8B62D;
  transition: 0.3s;
}
/* registration button */
.header-area .btn-registration {
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #C59535;
}
.header-area .btn-registration:hover {
  background-color: #F8B62D;
}
@media only screen and (max-width: 1199px) {
  .header-area .btn-registration {
    font-size: 14px;
    margin-right: 5px;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .btn-registration {
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .header-area .btn-registration i {
    display: none;
  }
}
/* mobile nav btn */
.btn-menu {
  color: #fff;
  background: #C59535;
  border: none;
  border-radius: 5px;
  display: none;
  margin: 0;
  padding: 0;
  font-size: 20px;
  margin-left: 8px;
  position: relative;
  height: 38px;
  width: 38px;
  line-height: 38px;
}
@media only screen and (max-width: 991px) {
  .btn-menu {
    display: inline-block;
  }
}
@media only screen and (max-width: 767px) {
  .btn-menu {
    height: 34px;
    width: 34px;
    font-size: 16px;
    line-height: 34px;
  }
}

/* Search popup
---------------------------------- */
.search-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    padding-left: 20px;
    padding-right: 20px;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-110%);
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}
.search-popup.active {
    opacity: 1;
    transform: translateY(0%);
}
.search-popup-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--moniz-black, #1e1e22);
    opacity: 0.75;
    cursor: pointer;
}
.search-popup-content {
    width: 100%;
    max-width: 860px;
}
.sr-only {
    border: 0;
    clip: rect(0,0,0,0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/* search top
---------------------------------- */
.search-top{
    display:block;
  padding: 17px 0px;
}
.search-top form{
    width: 50%;
}
.search-result-wrap{
    margin-left: 140px;
}
.search-min-wrap {
    display: none;
}
@media only screen and (max-width: 1199px){
    .search-result-wrap{
        margin-left: 0px;
    }
}
@media only screen and (max-width: 767px) {
    .search-top{
        display:none;
    }
    .search-result-wrap{
        margin-left: 0px;
    }
    .search-min-wrap {
        display: block;
    }
}
@media only screen and (max-width: 991px) {
    .search-top form{
        width: 60%;
    }
    .search-result-wrap{
        margin-left: 0px;
    }
}
.me-60{
    margin-right: 60px;
}
.ms-150{
    margin-left: 150px;
}
.me-70{
  margin-right: -70px;
}

.search-top-wrap{
  padding: 3px !important;
  opacity: 1 !important;
  box-shadow: 0 0 0 0 rgb(0 0 0 / 0%), 0 2px 2px rgb(0 0 0 / 4%);
}

/* Affix Menu - Sidebar */
.sidebar-box.affix {
	position: static;
}
@media (min-width:992px) {
  .sidebar-box.affix,
	.sidebar-box.affix-bottom {
		width: 27vw;
	}
	.sidebar-box.affix {
		position: fixed;
		top: 110px;
	}
	.sidebar-box.affix-bottom {
		position: absolute;
	}
}


/* search result
---------------------------------- */
.search-form-wrap{
  -webkit-box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, .1);
    background-image: url('../images/bg-search.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #326292;
    background-position: center center;
    background-repeat: no-repeat;
}

.search-result-section{
  padding: 10px 0 50px 0;
}
.result-list-none{
  padding-top: 50px;
  padding-bottom: 180px
}
.result-list-none p{
  color: #9E9E9E;
  line-height: 26px;
  margin-bottom: 0;
}
.search-result-section em{
  color: #c33;
  font-style: normal;
}
.search-result-info{
  color: #9E9E9E;
  font-size: 0.9375rem;
  margin-bottom: 10px;
}

.result-list-item{
  margin-bottom: 35px;
}
.result-list-item h3{
  font-weight: 400;
  font-size: 1.125rem;
  text-overflow: clip;
  margin-bottom: 10px;
}
.result-list-item h3 a {
  color: #606060;
}
.result-list-item h3 .note {
  margin-left: 1px;
  color: #9E9E9E;
  font-size: 0.9375rem;
}
.result-list-item h3 .note a{
  font-style: italic;
}
.result-list-item p{
  color: #606060;
  line-height: 1.6rem;
  word-wrap: break-word;
  word-break: break-all;
  margin-bottom: 5px;
}
.result-list-item span{
  color: #9E9E9E;
  font-size: 0.9375rem;
}
.result-list-item span a{
  color: #9E9E9E;
}
.result-list-item span a:hover{
  color: #F8B62D;
}
#search-tools{
  display:block;
}
.accordion-body .form-check-label{
    display:flex;
    justify-content: space-between;
}
.accordion-body .count{
    color: #9E9E9E;
    font-size: 0.9375rem;
}
.search-tools-btn{
  color: #fff;
  background-color: #9E9E9E;
  border-color: #909090;
}
.search-tools-btn:hover{
  color: #fff;
  background-color: #F8B62D;
  border-color: #C59535;
}
.search-tools-btn.active{
  color: #fff;
  background-color: #C59535;
  border-color: #C59535;
}
.search-item{
  margin-bottom: 10px;
  font-size: 0.9rem;
}

/* Offcanvas Header
---------------------------------- */
.off-canvas-wrapper.offcanvas {
  background-color: #fff;
  width: 310px;
}
@media only screen and (max-width: 991px) {
  .off-canvas-wrapper.offcanvas {
    width: 310px;
  }
}
.off-canvas-wrapper .offcanvas-header {
  padding: 0;
}
.off-canvas-wrapper .offcanvas-body {
  padding: 0 0 20px;
  scrollbar-width: auto;
  scrollbar-color: #1f1f1f #292929;
  /* Chrome, Edge, and Safari */
}
.off-canvas-wrapper .offcanvas-body::-webkit-scrollbar {
  width: 2px;
}
.off-canvas-wrapper .offcanvas-body::-webkit-scrollbar-track {
  background: #292929;
}
.off-canvas-wrapper .offcanvas-body::-webkit-scrollbar-thumb {
  background-color: #1f1f1f;
  border-radius: 2px;
  border: 2px solid #1f1f1f;
}
.off-canvas-wrapper .btn-menu-close {
  display: block;
  width: 100%;
  height: 60px;
  background-color: #C59535;
  opacity: 1;
  border-radius: 0;
  color: #fff;
  background-image: none;
  padding: 0 20px;
  line-height: 60px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  font-weight: 600;
  text-align: left;
  border: none;
  position: relative;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}
.off-canvas-wrapper .btn-menu-close i {
  position: absolute;
  right: 21px;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
}
.off-canvas-wrapper .btn-menu-close:hover {
  color: #fff;
  background-color: #2f2f2f;
}

.offcanvas-backdrop,
.modal-backdrop {
  background-color: rgba(43, 43, 43, 0.92);
  cursor: url(""), auto;
}
.offcanvas-backdrop.show,
.modal-backdrop.show {
  opacity: 1;
}

.mobile-menu-items {
  padding: 0;
}
.mobile-menu-items ul {
  padding: 0;
  border-top: 1px solid rgba(10, 77, 60, 0.15);
}
.mobile-menu-items ul li {
  padding: 0;
  list-style: none;
  position: relative;
}
.mobile-menu-items ul li a {
  display: block;
  width: 100%;
  background: none;
  text-align: left;
  padding: 10px 20px;
  border: 0;
  border-bottom: 1px solid rgba(10, 77, 60, 0.15);
  font-size: 15px;
  font-weight: 600;
  font-family: "Jost", sans-serif;
  color: #272a33;
  text-decoration: none;
}
.mobile-menu-items ul li a:hover {
  color: #C59535;
}
.mobile-menu-items ul li .mobile-menu-expand {
  position: absolute;
  right: 20px;
  top: 15px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.mobile-menu-items ul li .mobile-menu-expand::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 3px;
  background-color: #C59535;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.mobile-menu-items ul li .mobile-menu-expand::after {
  position: absolute;
  content: "";
  width: 3px;
  height: 100%;
  background-color: #C59535;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.mobile-menu-items ul li.active-expand > .mobile-menu-expand::before {
}
.mobile-menu-items ul li.active-expand > .mobile-menu-expand::after {
  height: 0;
}
.mobile-menu-items ul li.active-expand a {
  color: #C59535;
}
.mobile-menu-items ul li.active-expand ul li a {
  color: #C59535;
}
.mobile-menu-items ul li ul {
  border-top: 0;
}
.mobile-menu-items ul li ul li a {
  padding-left: 12%;
}
.mobile-menu-items ul li ul li a:hover {
  color: #C59535;
}
.mobile-menu-items ul li ul li ul li a {
  padding-left: 18%;
}
.main-bgimg{
  font-size: 0.9rem;
  padding: 0.5rem 0;
  vertical-align: middle;
  text-align: right;
  position: relative;
}
.main-bgimg i{
  position: absolute;
  top: -38px;
  color: #FFF;
  font-size: 60px;
}
.main-bgimg a{
  color: #999999;
}
.main-bgimg a:hover{
  color: #C59535;
}

/* Main wrap
---------------------------------- */
.main-wrap {
  width: 100%;
    background-color: #326292;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  position: inherit;
}
/* 遮罩层 */
.main-wrap .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  opacity: .2;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1);
  transform: scale(1);
}
/* search wrap */
.slogan {
  color: #fff;
    text-align: center;
}
.slogan p {
  font-family: Arial;
  font-size: 20px;
}
.slogan h1 {
  color: #fff;
  font-weight: 800;
  font-size: 60px;
}
.search-wrap{
  position: relative;
  background: #fff;
  border-radius: 23px;
  margin: 3rem 3rem 1rem;
  display: flex;
  padding: 8px;
  align-items: center;
    text-align:center;
  opacity: 0.9;
}
.search-wrap .search-input{
  box-sizing: border-box;
  color: #8f8f8f;
  font-size: 1rem;
  background: #E9E9E9;
  box-shadow: none;
  border: 0px solid #e6dcdc;
  border-radius: 19px 0px 0px 19px;
  position: relative;
  padding: 15px 25px;
  outline: none;
}
.search-wrap .search-btn{
  box-sizing: border-box;
  white-space: nowrap;
  background: #c59535;
  color: #FFFFFF;
  text-align: center;
  border-radius: 0px 19px 19px 0;
  padding: 15px 25px;
  font-size: 1rem;
  font-weight: 400;
  border: none;
  box-shadow: none;
}
.search-wrap .search-btn:hover{
  background: #476d52 !important;
  color: #FFFFFF;
}
.form-class
{
    display: flex;
    justify-content: left;
    margin-left: 5rem;
    margin-bottom: 2rem;
    color: #FFFFFF;
}
.form-class .form-check
{
  margin-right: 10px;
}
@media only screen and (max-width: 767px) {
    .search-wrap .search-btn span{
        display:none;
    }
    .form-class
    {
        display: flex;
        justify-content: space-evenly;
        margin: 0 30px;
        color: #FFFFFF;
    }
}
.animate {
  opacity: 0;
  visibility: hidden;
}
/* Mouse scroll effect */
.mouse {
  position: absolute;
  z-index: 5;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  display: block; }
  .mouse .mouse-icon {
    display: block;
    width: 25px;
    height: 45px;
    border: 2px solid white;
    border-radius: 15px;
    cursor: pointer;
    position: relative;
    text-align: center;
    -webkit-transition: .3s all ease-in-out;
    -o-transition: .3s all ease-in-out;
    transition: .3s all ease-in-out; }
  .mouse .mouse-wheel {
    height: 4px;
    margin: 2px auto 0;
    display: block;
    width: 4px;
    background-color: white;
    border-radius: 50%;
    -webkit-animation: 1.6s ease infinite wheel-up-down;
    -moz-animation: 1.6s ease infinite wheel-up-down;
    animation: 1.6s ease infinite wheel-up-down; }
  .mouse:hover .mouse-icon {
    border-color: #fff; }

    @-webkit-keyframes wheel-up-down {
      0% {
        margin-top: 2px;
        opacity: 0; }
      30% {
        opacity: 1; }
      100% {
        margin-top: 20px;
        opacity: 0; } }

    @-moz-keyframes wheel-up-down {
      0% {
        margin-top: 2px;
        opacity: 0; }
      30% {
        opacity: 1; }
      100% {
        margin-top: 20px;
        opacity: 0; } }

    @keyframes wheel-up-down {
      0% {
        margin-top: 2px;
        opacity: 0; }
      30% {
        opacity: 1; }
      100% {
        margin-top: 20px;
        opacity: 0; } }

/* About section
---------------------------------- */
.about-section{
  padding-top: 50px;
}
.about-item {
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  background: #fff;
  transition: .4s;
}
.about-item:hover{
  background: rgba(197,149,53,0.2);
  transition: .4s;
}
.about-item p{
  text-align: left;
}
.about-item p span{
  color: #C59535;
}
.about-item h3 {
  margin-top: 28px;
  margin-bottom: 14px;
  font-size: 1.5rem;
}

/*   Counts section
---------------------------------- */
.count-section{

}
@media only screen and (min-width: 992px) and (max-width: 1199px){
  .count-section .col-lg-6:first-child {
    width: 55%;
  }
  .count-section .col-lg-6:last-child {
    width: 45%;
  }
}
.section-header{
  margin-top: 5px;
  margin-bottom: 30px;
}
.section-header h3{
  position: relative;
  font-weight: 900;
  font-size: 1.75rem;
  line-height: 1.1;
  margin-bottom: 15px;
}
.section-header h3 span{
  color: #C59535;
}
.section-header p{
  padding-left: 1.125rem;
  color: #333333;
  line-height: 26px;
  position: relative;
  margin-bottom: 0;
}
.section-header p:after {
  background: #C59535 none repeat scroll 0 0;
  content: "";
  height: 78%;
  left: 0;
  position: absolute;
  top: 7px;
  width: 3px;
}

.count-wrap {
  display: flex;
  gap: 30px;
}

@media only screen and (max-width: 767px) {
  .count-wrap {
    flex-direction: column;
  }
}
@media only screen and (max-width: 992px){
  .count-wrap {
    justify-content: center;
  }
}
.count-item {
  background: #F8F7F3;
  padding: 20px 25px;
  text-align: center;
  border-radius: 8px;
  transition: .3s;
}
.count-item:hover {
  background: #F8F7F3;
}
.count-item:hover i,
.count-item:hover span,
.count-item:hover p {
  color: #C59535;
}
.count-item:hover {
  box-shadow: 2px 5px 5px 0px rgba(197,149,53,0.2);
  transition: .3s;
}
.count-item i {
  display: block;
  height: 64px;
  font-size: 2.5rem;
  color: #C59535;
  margin-bottom: 17px;
}
.count-item i.count_icon{
  background-color: rgb(255, 255, 255, 0.2) !important;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}
.count-item i.boudhanath{
  background-image: url('../images/icons/icon-boudhanath-l.png');
}
.count-item:hover i.boudhanath{
  background-image: url('../images/icons/icon-boudhanath-c.png');
  transition: .3s;
}
.count-item i.sutra{
    background-image: url('../images/icons/icon-paper-l.png');
}
.count-item:hover i.sutra{
    background-image: url('../images/icons/icon-paper-c.png');
    transition: .3s;
}
.count-item i.buddhist{
    background-image: url('../images/icons/icon-book-l.png');
}
.count-item:hover i.buddhist{
    background-image: url('../images/icons/icon-book-c.png');
    transition: .3s;
}
.count-item i.endless{
  background-image: url('../images/icons/icon-endless-l.png');
}
.count-item:hover i.endless{
  background-image: url('../images/icons/icon-endless-c.png');
  transition: .3s;
}
.count-item span {
  font-size: 2.0rem;
  display: block;
  min-width: 100px;
  color: #1E3322;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
}
.count-item p {
  font-size: 1.125rem;
  color: #717178;
  font-weight: 700;
  line-height: 20px;
  margin: 0;
  padding: 10px 0;
}
.count-btn{
  margin-top: 30px;
  display: flex;
  gap: 30px;
}
@media only screen and (max-width: 767px){
  .count-btn {
    gap: 15px;
  }
}
@media only screen and (max-width: 992px){
  .count-btn {
    margin-top: 50px;
    justify-content: center;
    margin-bottom: 45px;
  }
}
.words-wrap{
  background-image: url('../images/bg_word.png');
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #F8F7F3;
  border-radius: 10px;
}
@media only screen and (max-width: 767px){
  .words-wrap {
  background-size: auto;
  }
}
.words-header{
  position: relative;
}
.words-header h3 {
  position: absolute;
  display: block;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 500;
  color: #C59535;
  top: 14px;
}
.word-item{
  padding: 30px 20px 10px 20px;
  margin: 30px;
}
.word-header{
  font-size: 1.5rem;
  margin-bottom: 20px;
}
.word-content{
  font-size: 1.125rem;
  line-height: 2rem;
}
.word-link{
  display: flex;
  padding: 10px 0;
}
.word-link a{
  padding-left: 20px;
  padding-right: 20px;
  background-color: #558263;
  border-color: #4D755A;
  color: #FFFFFF;
}
.word-link a:hover{
  background-color: #4D755A;
  border-color: #476d52;
  color: #FFFFFF;
}
@media only screen and (max-width: 767px){

  .word-item{
    padding: 20px 10px;
    margin: 30px 0;
  }
  .words-header h3 {
    text-align: left;
    left: 10px;
  }
}
@media only screen and (max-width: 992px){
  .words-header h3 {
    text-align: left;
    left: 10px;
  }
}

/*  Categories section
---------------------------------- */
.categories-section{

}
.category-item{
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 58px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}
.category-item .overlay-link {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.category-item .title{
  color: #272a33;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0;
}
.category-item .title a{
  color: #272a33;
}
.category-item:hover {
  background-color: #588263;
}
.category-item:hover .title a {
  color: #fff;
}

.categories-list{
  text-align: left;
  margin-bottom: 30px;
}
.categories-list .nav-link{
  color: #272a33;
  font-size: 1.125rem;
  font-weight: 500;
}
.categories-list .nav-link:hover{
  color: #C59535;
}

/* Collection section
---------------------------------- */
.collection-home-section{

}
.collection-home-wrap{

}
.coll-item{
  position: relative;
  margin-left: 15px;
  margin-bottom: 15px;
}
.coll-item-thumb{
  overflow: hidden;
}
.coll-item-thumb img {
  width: 100%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.coll-item-content {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  cursor: pointer;
  background: rgba(71, 187, 143, 0);
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.coll-item-content-inner{
  width: 100%;
  padding: 20px;
  -webkit-transform: translateY(0) translateZ(0);
  -moz-transform: translateY(0) translateZ(0);
  -ms-transform: translateY(0) translateZ(0);
  -o-transform: translateY(0) translateZ(0);
  transform: translateY(0) translateZ(0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
.coll-item-content > * {
  color: #fff;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 0.6s cubic-bezier(0, 0, 0.2, 1) 0s;
  -moz-transition: all 0.6s cubic-bezier(0, 0, 0.2, 1) 0s;
  -ms-transition: all 0.6s cubic-bezier(0, 0, 0.2, 1) 0s;
  -o-transition: all 0.6s cubic-bezier(0, 0, 0.2, 1) 0s;
  transition: all 0.6s cubic-bezier(0, 0, 0.2, 1) 0s;
}
.coll-item-content h3 {
  color: #fff;
  margin-bottom: 0;
  visibility: hidden;
  font-size: 1.125rem;
  font-weight: 700;
  opacity: 0;
  -webkit-transform: translateX(-50px);
  -moz-transform: translateX(-50px);
  -ms-transform: translateX(-50px);
  -o-transform: translateX(-50px);
  transform: translateX(-50px);
  -webkit-transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0.05s;
  -moz-transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0.05s;
  -ms-transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0.05s;
  -o-transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0.05s;
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0.05s;
}
.coll-item-content h3 a{
  color: #FFFFFF !important;
}
.coll-item:hover .coll-item-thumb img {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2); }
.coll-item:hover .coll-item-content {
  background: rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }
  .coll-item:hover .coll-item-content > * {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
    opacity: 1; }
  .coll-item:hover .coll-item-content h3 {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    visibility: visible;
    opacity: 1; }
.coll-item:hover .coll-item-content-inner {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0); }

/**************************
  make offerings section
***************************/
.mo-section {

}
.sutra-xj {
  background: #FFFCF6;
  color: #645647;
  padding: 1px;
  border: 1px solid #ebe6d9;
}
.sutra-xj h4 {
  font-size: 1.125rem;
}
.verticle-mode{
  box-sizing: border-box;
  width: 100%;
  background-image: url('../images/icons/lotus.png');
  background-repeat: no-repeat;
  background-size: 16%;
  background-position: 92% 90%;
  border: 1px solid #ebe6d9;
  padding: 20px 20px 20px 20px;
}
@media only screen and (min-width: 1199px) and (max-width: 1962px){
  .verticle-mode{
    padding-right:360px !important;
  }
}
.verticle-modeh4{
  float:right;
}

/* support
---------------------------------- */
.support-wrapper {
}
.supitems {
  display: flex;
  gap: 30px;
}
.supitem{
  width: 68px;
  text-align: center;
  cursor: pointer;
}
.supitem i {
  display: block;
  height: 32px;
  margin-bottom: 0px;
}
.supitem p{
  font-size: 0.85rem;
  margin-bottom: 0px;
}
.supitem .counter {
  font-size: 1.5rem;
  font-family: Arial;
}
.supitem i.supicon{
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  opacity: 0.3;
}
.supitem:hover i{
  opacity: 1;
  transition: .3s;
}
.supitem:hover p{
  color: #C59535;
}
.supitem i.maked{
  opacity: 1;
}
.supitem i.incense{
  background-image: url('../images/icons/incense-l.png');
}
.supitem:hover i.incense, .supitem i.supincense{
  background-image: url('../images/icons/incense-c.png');
}
.supitem i.lotus{
  background-image: url('../images/icons/lotus1-lf.png');
}
.supitem:hover i.lotus{
  background-image: url('../images/icons/lotus1-cf.png');
}
.supitem i.candle{
  background-image: url('../images/icons/candle-l.png');
}
.supitem:hover i.candle{
  background-image: url('../images/icons/candle-c.png');
}
.supitem i.fruits{
  background-image: url('../images/icons/fruits-l.png');
}
.supitem:hover i.fruits{
  background-image: url('../images/icons/fruits-c.png');
}
.supfubao{
  max-width: 380px;
  font-size: 1.6rem;
  margin: 10px auto;
  padding: 10px;
  font-weight: 500;
  color: #C59535;
  border-top: 2px solid #C59535;
}

/* footer section
---------------------------------- */
#footer{
  color: #444444;
  font-size: 14px;
  background: #f1f1f1;
}
.footer-area{
  background: #f9f9f9;
  padding: 30px 0 30px 0;
}
.footer-item{
  margin-top: 1.5rem;
  margin-bottom: 0.25rem;
}
.footer-item h6{
  margin-bottom: 1rem;
}
.footer-item p{
  font-size: 0.8rem;
  line-height: 1.6;
}
.footer-item a:hover{
  color: #F8B62D;
  transition: 0.3s;
}
.footer-item p span{
  color: #C59535;
}
.footer-item ul{
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-item ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
}
.footer-item ul li i{
  padding: 0 5px;
  color: #C59535;
  font-size: 18px;
  line-height: 1;
}
.footer-item ul li a{
  color: #777777;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}
.footer-item ul li a:hover{
  color: #C59535;
  transition: 0.3s;
}
.box-widget{
  list-style-type: none;
  padding-left: 0;
  overflow: hidden;
  margin: 0;
}
.box-widget li {
  float: left;
  padding: 3px !important;
}
.copyright{
  line-height: 30px;
}
.social-links{
  display: flex;
  gap: 5px;
}
.social-links a {
    /*width: 30px;*/
    height: 30px;
    display: block;
    text-align: center;
    /*background: #C59535;*/
    /*border: 0px solid #C59535;*/
    /*border-radius: 100%;*/
}
.social-links a i{
    font-size: 1rem;
    line-height: 31px;
    /*color: #FFFFFF !important;*/
}
.social-links a:hover{
  /*background: #F8B62D;*/
}

.bottom-footer {
  font-size: 0.625rem;
}
.bottom-footer a{
  color: #444444;
}
.bottom-footer a:hover{
  color: #C59535;
}

/* banner wrap
---------------------------------- */
.banner-wrap{
  position: relative;
  height: 380px;
  background-color: #F1F1F1;
  -webkit-box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, .1);
}
.fixed-image{
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
    background-size: cover;
    background-color: #326292;
  background-position: center center;
  background-repeat: no-repeat;
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}
.image-info{
  position: absolute;
  bottom: 0;
  right: 0px;
  padding: 5px 15px;
  border-radius: 10px 0 0 0;
  text-align: right;
  background-color: RGB(255,255,255,0.6);
}
.image-info i{
  color: #588263;
}
.image-info a{
  color: #999999;
}
.image-info a:hover{
  color: #F8B62D;
}

/* classic list item
---------------------------------- */
.classic-list-header-section{
padding-bottom: 0px;
}
.cate-search-filter{
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 10px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid #DEE2E6;
}
@media only screen and (max-width: 767px){
  .cate-search-filter {
      grid-template-columns: 1fr;
      grid-gap: 15px;
  }
}
.cate-filter{
  margin: 5px 0;
}
.cate-nav .nav-item:first-child{
  margin-left: 0;
}
.cate-nav .nav-item{
  margin: 0 5px;
}
.cate-nav .nav-link{
  color: #444444;
  border-radius: 5px;
}
.cate-nav .nav-link:hover,
.cate-nav .nav-link.active{
  color: #fff;
  background: #588263;
}

.widget {
    display: block;
    border: 1px solid #F2F1F0;
    padding: 8px 8px;
    border: 0px solid #fff4f4;
    background: #fff;
    border-radius: 10px;
}
.widget-search {
    background: #f7f7f7;
}
.widget-search .input-group input {
    font-weight: 400;
    color: #A9A9AB;
    background: #FFFFFF;
    box-shadow: none;
    border: 0px solid #e6dcdc;
    border-radius: 12px !important;
}
.classic-item{
  border-radius: 10px;
  padding: 20px 20px;
  margin-bottom: 30px;
  background-color: #F8F7F3;
}
.classic-item .title{
  font-size: 1.375rem;
  margin-bottom: 5px;
}
.classic-item .meta{
  color: #9E9E9E;
  font-size: 0.9375rem;
  margin-bottom: 10px;
}
.classic .meta a,
.classic-item .meta a{
  color: #9E9E9E;
}
.classic .meta a:hover,
.classic-item .meta a:hover{
  color: #F8B62D;
}
.classic-item p{
  color: #606060;
  line-height: 1.55rem;
  margin-bottom: 20px;
    min-height: 100px !important;
}
.rating{
  display: flex;
  justify-content: space-between;
}
.rating .rat {
  color: #606060;
  font-size: 0.9rem;
  line-height: 2rem;
}
 .rating .links{
   display: flex;
   gap: 10px;
   position: relative;
 }
.rating a.read {
  background-color: #588263;
  color: #FFFFFF;
  font-size: 0.9rem;
  border-radius: 10px;
  height: 32px;
  padding: 2px 10px;
}
.rating a.view {
  background-color: #C59535;
  color: #FFFFFF;
  border-radius: 16px;
  height: 32px;
  width: 32px;
  position: relative;
  line-height: 2;
}
.rating a.view i{
  position: absolute;
  left: 25%;
  top: 25%;
}
.rating a:hover {
  background-color: #588263;
  transition: .3s;
}

/* classic & collection details
---------------------------------- */
.classic-details,
.sutra-details,
.collection-details{
 position: relative;
 display: block;
}
.classic-details-content,
.about-content,
.coll-details-content {
  position: relative;
  display: block;
}
.classic-details-content .title,
.sutra-details .title,
.coll-details-content .title{
  margin-bottom: 10px;
}
.classic-details-content .meta,
.sutra-details .meta,
.coll-details-content .meta{
  color: #9E9E9E;
  font-size: 0.9375rem;
  line-height: 1.3;
  margin-bottom: 25px;
}
.classic-details-content .meta a,
.coll-details-content .meta a{
  color: #9E9E9E;
}
.classic-details-content .meta a:hover,
.sutra-details .meta a:hover,
.coll-details-content .meta a:hover {
  color: #F8B62D;
  text-decoration: underline;
}
.classic-details-content .content,
.coll-details-content .content{
  color: #606060;
  font-size: 1.125rem;
  text-indent: 2rem;
  line-height: 2;
}
.classic-details-content .content a,
.coll-details-content .content a{
    color: #C59535;
    text-decoration: none;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}
.classic-details-content .content a:hover,
.coll-details-content .content a:hover{
    color: #F8B62D;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    text-decoration: none;
}
.classic-details-content .content h5
.coll-details-content .content h5{
  margin-top: 3rem;
  margin-bottom: 1rem;
  text-indent: 0;
}
.classic-details-content .content ol,
.about-content ol,
.coll-details-content .content ol{
    margin-left: 2rem;
}
.classic-details-content .content ol li,
.about-content ol li,
.coll-details-content .content ol li{
    text-indent: 0;
}
.classic-details-content .content ul,
.about-content ul,
.coll-details-content .content ul{
  padding-left: 0;
}
.classic-details-content .content ul li,
.about-content ul li,
.coll-details-content .content ul li{
  list-style-type: disc;
  list-style-position: inside;
}
.classic-details-content .content ul li a,
.coll-details-content .content ul li a{
    position: unset !important;
    display: unset !important;
    margin-left: unset !important;
    padding-left: unset !important;
    font-size: unset !important;
}
.classic-details-content .content ol li a,
.coll-details-content .content ol li a{
  position: relative;
  display: block;
  margin-left: 0px;
  padding-left: 20px;
  color: #588263 !important;
  font-size: 0.9rem;
}
.classic-details-content .content ol li a:hover,
.coll-details-content .content ol li a:hover{
  text-decoration: underline;
}
.classic-details-content .content ol li a:after,
.coll-details-content .content ol li a:after{
  font-family: "FontAwesome";
  content: "\f0c1";
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  left: 0;
  top: 3px;
  color: #588263;
}
.classic-details-content .content .img-wrapper,
.about-content .img-wrapper,
.coll-details-content .content .img-wrapper{
  margin-top: 30px;
  margin-bottom: 10px;
  text-indent: 0;
  text-align: center;
}
.classic-details-content .content .img-wrapper img,
.about-content .img-wrapper img,
.coll-details-content .content .img-wrapper img{
  max-width: 95%;
}
.classic-details-content .content .img-wrapper .img-desc,
.about-content .img-wrapper .img-desc,
.coll-details-content .content .img-wrapper .img-desc{
  line-height: 20px;
  padding: 6px 0;
  font-size: 0.8rem;
  margin: 5px auto;
  display: block;
}

.classic-details-content .content .imgwd,
.about-content .imgwd,
.coll-details-content .content .imgwd{
    margin-top: 30px;
    margin-bottom: 10px;
    text-indent: 0;
    display: flex;
    justify-content: space-around;
}
.classic-details-content .content .imgw,
.about-content .imgw,
.coll-details-content .content .imgw{
    margin-top: 30px;
    margin-bottom: 10px;
    text-indent: 0;
    text-align: center;
}
.classic-details-content .content .imgw img,
.about-content .imgw img,
.coll-details-content .content .imgw img{
    max-width: 95%;
}
.classic-details-content .content .imgw .imgd,
.about-content .imgw .imgd,
.coll-details-content .content .imgw .imgd{
    line-height: 20px;
    padding: 6px 0;
    font-size: 0.8rem;
    margin: 5px auto;
    display: block;
}
.about-content blockquote,
.classic-details-content .content blockquote {
    border-left: 5px solid #ecf0f1 !important;
}
.about-content blockquote p,
.classic-details-content .content blockquote p{
    text-indent: 0px;
}


/* details bottom
---------------------------------- */
.details-bottom{
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 1px solid var(--moniz-extra, #e4e4ea);
  padding: 15px;
}
.detail-tools {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 50px 0 50px;
}
.detail-tools div {
    text-align: center;
    display: block;
    width: 100%;
}
.detail-tools .btn {
    position: relative;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    text-decoration: none;
    font-weight: 400;
    line-height: 1.5;
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
    transition: all .3s ease-in-out !important;
    -moz-transition: all .3s ease-in-out !important;
    -webkit-transition: all .3s ease-in-out !important;
    -o-transition: all .3s ease-in-out !important;
}
.tools-btn {
    color: #FFFFFF !important;
    padding: 0.75rem 2rem;
    background-color: #C59535;
}
.pdfBtn {
    background-color: #558263;
}
.pdfBtn:hover {
    color: #FFFFFF;
    background-color: #4d755a;
    transition: all .3s ease-in-out !important;
    -moz-transition: all .3s ease-in-out !important;
    -webkit-transition: all .3s ease-in-out !important;
    -o-transition: all .3s ease-in-out !important;
}
.tags{

}
.tags a{
  font-size: 0.9rem;
}
.share-list{
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.tags span,
.share-list span{
  font-weight: 700;
  margin-right: 10px;
}
.share-list a+a{
  margin-left: 10px;
}
.share-list a{
  width: 30px;
  height: 30px;
  background: #f1f0f2;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  margin-right: 0px;
}

/* sidebar box
---------------------------------- */
.sidebar-box{
  margin-bottom: 15px;
  padding: 15px 25px;
  font-size: 1rem;
  width: 100%;
  float: left;
}
.sidebar-box .title::before{
  background-color: #C59535;
  content: "";
  height: 3px;
  position: absolute;
  width: 38px;
  bottom: 0;
  left: 0;
}
.sidebar-box .title {
  font-size: 1.125rem;
  margin-bottom: 15px;
  padding-bottom: 10px;
  display: inline-block;
  position: relative;
}
.sidebar-box .title i {
  color: #C59535;
  cursor: pointer;
}
.classic-info a.dict{
  color: #588263;
}
.classic-info a.dict:hover{
  color: #0f5132;
}
.classic-info .counter{
  font-size: 2rem;
  padding: 0 10px;
}
.sutra-list {
    margin-top: 10px;
    padding-left: 1rem;
}
.sutra-list li{
    list-style-type: disc;
  margin-bottom: 10px;
    line-height: 1.3rem;
}
.sutra-list li span{
    font-size: 0.9rem;
    color: #606060;
}
.dict-cate a {
  text-transform: uppercase;
  display: inline-block;
  padding: 4px 10px;
  margin-bottom: 4px;
  margin-right: 2px;
  border-radius: 5px;
  color: #606060;
  background-color: #F8F7F3;
  font-size: 0.9rem;
}
.dict-cate a.active,
.dict-cate a:hover {
  color: #FFFFFF;
  background-color: #588263;
}



/* more words
---------------------------------- */
.more-words {
  color: #606060;
}
.more-words span {
  display: inline-block;
  white-space: nowrap;
}
.more-words span a{
  color: #9e9e9e;
  font-size: 0.9rem;
  padding: 0.1rem 0rem;
  display: inline-block;
  white-space: nowrap;
}
.more-words span a:hover{
  color: #F8B62D;
}
/* more classic
---------------------------------- */
.more-classic{
  margin-bottom: 20px;
  position: relative;
  padding-left: 35px;
}
.more-classic i{
  position: absolute;
  top: 4px;
  left: 0;
  width: 25px;
  height: 25px;
  text-align: center;
  color: #666666;
}
.more-classic p{
  font-size: 0.9rem;
  color: #606060;
  margin-bottom: 20px;
}

/* more collections
---------------------------------- */
.more-collections{
  padding: 0 10px;
}
.more-coll{
  padding-right: 2px;
  padding-left: 2px;
  margin-bottom: 4px;
}
.overlay-container
{
  position: relative;
  display: block;
  overflow: hidden;
}
.overlay-container:hover .overlay,
.overlay-container:hover .overlay-links {
	opacity: 1;
	filter: alpha(opacity=100);
	-webkit-transform: scale(1);
	transform: scale(1);
}
.coll-image.overlay-container:hover .overlay{
  border-radius: 10px 10px 0 0;
}
.overlay {
    position: absolute;
    top: 0;
    bottom: -1px;
    left: 0;
    right: -1px;
    background-color: rgba(241, 241, 241, 0.75);
    overflow: hidden;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transition: all linear 0.2s;
    -moz-transition: all linear 0.2s;
    -ms-transition: all linear 0.2s;
    -o-transition: all linear 0.2s;
    transition: all linear 0.2s;
  }
.overlay a{
  display: inline-block;
  width: 50px;
  height: 50px;
  font-size: 26px;
  color: #C59535;
  background-color: #ffffff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
}
a.overlay{
  background-color: RGB(255,255,255, 0.75);
  cursor: pointer;
}
a.overlay i {
  position: absolute;
  left: 50%;
  top: 50%;
	font-size: 40px;
	line-height: 42px;
	color: #C59535;
  font-size: 25px;
  margin-top: -12px;
  margin-left: -12px;
}
a.overlay.small i {
	font-size: 22px;
	line-height: 1;
	margin-top: -10px;
	margin-left: -10px;
}
.overlay-links {
  display: block;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  left: 50%;
  margin-left: -50px;
  font-size: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0.4);
  transform: scale(0.4);
  -webkit-transition: all 0.25s linear 0.05s;
  -moz-transition: all 0.25s linear 0.05s;
  -ms-transition: all 0.25s linear 0.05s;
  -o-transition: all 0.25s linear 0.05s;
  transition: all 0.25s linear 0.05s;
}
.overlay-links a {
	position: static;
	margin-top: 0;
	margin-left: 0;
  border-right: 1px dashed #C59535;
}
.overlay-links a + a {
	left: 50px;
	color: #ffffff;
	background-color: #C59535;
  border-right: 0;
}
.overlay a:hover {
	color: #ffffff;
	background-color: #606060;
}
.overlay i {
	line-height: 50px;
}

/* words list item
---------------------------------- */
.words-list{
  padding-bottom: 10px;
}
.words-list-item{
  margin-bottom: 25px;
}
.words-list-item h3{
  font-weight: 400;
  font-size: 1.125rem;
  text-overflow: clip;
  margin-bottom: 10px;
}
.words-list-item p{
  color: #606060;
  line-height: 1.6rem;
  word-wrap: break-word;
  word-break: break-all;
  margin-bottom: 5px;
}
.words-list-item span{
  color: #9E9E9E;
  font-size: 0.9375rem;
}
.words-list-item span a{
  color: #9E9E9E;
}
.words-list-item span a:hover{
  color: #F8B62D;
}

.list-pagination{
  margin: 20px 0 40px;
}
.page-link {
  color: #606060;
}
.page-item.active .page-link,
.page-link:hover {
  color: #FFFFFF;
  background: #588263;
  border-color: #588263;
}

/* word detail
---------------------------------- */
.word-section{}
.word-details{
  display: block;
  position: relative;
}
.word-details-content{
  position: relative;
  display: block;
}
.word-details-content .title{
  font-size: 1.5rem;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.word-details-content .pinyin{
  font-size: 0.9rem;
  display: block;
  color: #9E9E9E;
  /*white-space: nowrap;*/
  margin-bottom: 10px;
}
.word-details-content .content-tab{

}

.nav-tabs{
  border-bottom: 1px solid #C59535;
}
.nav-tabs .nav-item:first-child {
  padding-left: 0;
}
.nav-tabs .nav-item {
  padding: 0 3px;
}
.nav-tabs .nav-link.active{
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-bottom: 5px solid #C59535;
  color: #606060;
  font-weight: 700;
}
.nav-tabs .nav-link:hover{
  border: 0;
  border-bottom: 5px solid #C59535;
  color: #606060;
  isolation: isolate;
  transition: .3s;
}
.nav-tabs .nav-link {
  font-size: 0.9rem;
  padding: 0.5rem;
  text-transform: uppercase;
  border: 0;
  position: relative;
  color: #9E9E9E;
  bottom: -4px;
}
.tab-content>.tab-pane{
  padding: 1rem;
}

.word-details-content .meta{
  /*font-size: 1.125rem;
  line-height: 1.3;*/
  color: #9E9E9E;
  margin-bottom: 10px;
  padding: 10px 0;
}
.word-details-content .link a,
.word-details-content .page-data a,
.word-details-content .meta a{
  color: #9E9E9E;
}
.word-details-content .link a:hover,
.word-details-content .page-data a:hover,
.word-details-content .meta a:hover {
  color: #F8B62D;
}
.word-details-content .meta span{
    font-style: italic;
    margin-left: 10px;
}
.word-details-content .page-data{
  font-size: 0.9rem;
  color: #9E9E9E;
  margin-top: 50px;
}
.word-details-content .link {
    font-size: 0.9rem;
    color: #9E9E9E;
    margin-top: 20px;
}
.word-details-content .link a{
    font-size: 0.9rem;
}
.word-details-content .content{
  color: #606060;
  font-size: 1.125rem;
  text-indent: 0;
  line-height: 2;
}
.markdown blockquote::before,
.word-details-content .content blockquote::before{
  color: #CCC;
  content: open-quote;
  font-size: 3rem;
  position:absolute;
  left: 10px;
  top: -10px;
  padding: 0 10px;
}
.markdown blockquote,
.classic-details-content .content blockquote,
.coll-details-content .content blockquote,
.word-details-content .content blockquote{
  border-left: 5px solid #C59535;
  color: #999;
  background: #f9f9f9;
  border-radius: 0 10px 10px 0;
  margin: 10px 0 10px 0;
  padding: 2em !important;
  quotes: "\201C""\201D""\2018""\2019";
  position: relative;
}
.markdown blockquote::after,
.word-details-content .content blockquote::after{
  color: #CCC;
  content: close-quote;
  font-size: 3rem;
  position:absolute;
  right: 20px;
  bottom: -30px;
}
.markdown blockquote p,
.word-details-content .content blockquote p{
  display: block;
  margin-bottom: 0px;
}

/* sutra details
---------------------------------- */
.sutra-section{
  padding-top:30px;
}
.sutra-details rb{
  padding:0 3px;
  text-align: center;
}
.sutra-details rt{
  color: #606060;
  font-weight: 500;
  text-align: center;
-webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

.sutra-details .title{
    font-size: 2.5rem;
    margin-bottom: 5px;
    padding-bottom: 5px;
}
.sutra-details .title rb{
    padding: 0 3px !important;
}
.sutra-details .title rt{
    font-size: 1rem !important;
}
.sutra-details .content{
    /*
    Microsoft YaHei UI,Microsoft YaHei,
     */
    font-family: PingFang SC,Hiragino Sans GB,Microsoft JhengHei,Source Han Sans CN,Arial,Songti !important;
  font-size: 1.5rem;
  text-indent: 3.4rem;
  line-height: 2.5;
  padding: 0 10px;
    overflow: hidden;
    position: relative;
}
.sutra-details .content .content div{			/*获取更多内容图标div*/
    clear:both;
    min-height:1em;
    white-space:pre-wrap; /*如何处理元素内的空白*/
}
.more {
    text-indent: 0px !important;
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: none;
    background: linear-gradient(to top, #fff, rgba(255, 255, 255, 0.8) 100%);
}
#readMore {
    width: 100%;
    line-height: 18px;
    cursor: pointer;
    display: block;
    text-align: center;
    font-size: 15px;
    color: #c59535;
}
#readMore:hover{
    color: #f8b62d;
    box-shadow: none;
    outline: none;
}

.sutra-details .sutra-mo-wrap
{
  padding: 30px 0;
}

.mini-sidebar
{
  display: none;
  position: absolute;
  z-index: 999;
  padding: 10px 0px;
  text-align: center;
}
.mini-sidebar ul {
  margin: 0;
  padding: 0;
}
.mini-sidebar li {
  margin-bottom: 10px;
}
.mini-sidebar li a {
  display: block;
  margin: 0 auto;
  width: 40px;
  color: inherit;
}
.mini-sidebar li a:hover {
  color: #C59535;
}
.mini-sidebar i {
  display: block;
  width: 30px;
  height: 30px;
  margin: 0px auto;
  font-size: 1rem;
  line-height: 30px;
  text-align: center;
  background-color: #C59535;
  color: #FFFFFF;
  border-radius: 50%;
}

@media only screen and (max-width: 991px){

  #search-tools{
    display:none;
  }
  .sutra-details .content{
    padding: 0 10px;
  }
  .sutra-details .title{
    font-size: 1.8rem;
  }
  .sutra-details .content
  {
    text-indent: 0;
  }
  .mini-sidebar{
    display: block;
    position: fixed !important;
    top: unset !important;
    right: 9px;
    bottom: 45px;
  }
  .mini-sidebar span
  {
    display: none;
  }
  #info, #catalogs {
    display: none;
  }
}

/* load more
---------------------------------- */
.load-more{
  padding: 20px 0;
  text-align: center;
}

/* download wrap
---------------------------------- */
.download-wrap{
  padding: 20px 20px;
  background-color: #F8F7F3;
  border-radius: 5px;
}
.download-wrap .header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px solid #ebe6d9;
}
.download-wrap .header h3{
  position: relative;
  font-weight: 900;
  font-size: 1.25rem;
  line-height: 1.1;
  margin-bottom: 5px;
}
.download-wrap .header p{
  font-size: 0.8125rem;
  color: #9E9E9E;
  margin: 0;
}

/* collection list section
---------------------------------- */
.collection-wrap {
  position: relative;
  height: 600px;
  background-color: #F1F1F1;
  -webkit-box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, .1);
}
.slick-item{
  display: block;
  height: 600px;
  position: relative;
}
/* caption */
.caption {
  border-radius: 10px;
	background-color: rgba(255, 255, 255, 0.7);
	padding: 15px;
	position: absolute;
	bottom: 90px;
	left: 80px;
	max-width: 40%;
  opacity: 0;
  transition: opacity .3s;
  -moz-transition: opacity .3s;
  -webkit-transition: opacity .3s;
  -o-transition: opacity .3s;
}
.caption .title {
	margin-top: 0;
}
.collection-wrap:hover .caption{
  opacity: 1;
  transition: opacity 1s;
  -moz-transition: opacity 1s;
  -webkit-transition: opacity 1s;
  -o-transition: opacity 1s;
}
.collection-item{
  border-radius: 10px;
  margin-bottom: 30px;
  background-color: #F8F7F3;
}
.collection-item .coll-image{
}
.coll-image img{
  border-radius: 10px 10px 0 0;
}
.coll-body{
  padding: 20px 15px 15px;
}
.coll-body .cate{
  color: #606060;
  font-size: 0.9375rem;
  margin-bottom: 5px;
}
.coll-body .cate a {
  color: #606060;
}
.coll-body .meta a:hover,
.coll-body .cate a:hover{
  color: #F8B62D;
}
.coll-body .title{
  font-size: 1.375rem;
  margin-bottom: 5px;
}
.coll-body .meta{
  color: #9E9E9E;
  font-size: 0.9375rem;
  margin-bottom: 10px;
}
.coll-body .meta a{
  color: #9E9E9E;
}
.coll-body p{
  color: #606060;
  line-height: 1.6rem;
  margin-bottom: 20px;
}

/* collection detail section
---------------------------------- */
.collection-images-section{
  padding: 0;
  background: rgb(0,0,0,1);
}
.image-item{
  padding: 0px;
  text-align: center;
  vertical-align: middle;
  margin:0 5px;
}
#coll-detail-images .slick-list img{
  /*max-height: 500px;*/
}
.mo-box{
  margin-top: 30px;
  padding: 15px 30px;
}
.collection-meta{

}
.collection-meta .meta{
  position: relative;
  padding-left: 35px;
  margin-bottom: 15px;
}
.collection-meta .meta i{
  position: absolute;
  top: 4px;
  left: 0;
  width: 25px;
  height: 25px;
  text-align: center;
  color: #666666;
}
.collection-meta .meta p{
    color: #606060;
    margin-bottom: 0;
}
.collection-meta .meta ul{
    padding-left: 1rem;
}
.collection-meta .meta ul li{
    color: #606060;
    list-style: circle;
    margin-bottom: 5px;
}
.collection-meta .meta ul li span{
    font-size: 0.9rem;
}
/* markdown custome style */
.markdown a {
    color: #C59535 !important;
    text-decoration: none;
}
.markdown p, .markdown blockquote, .markdown ul, .markdown ol, .markdown dl, .markdown table, .markdown pre {
     margin-top: 0;
     margin-bottom: 20px !important;
 }
 .markdown img{
    margin-bottom: 10px;
 }
 .markdown img+img{
    margin-left: 10px;
 }

  /**
  修改bootstrap 样式
   */
 .accordion-item{
     border: 0;
     background-color: unset !important;
 }
 .accordion-header {
     border-bottom: 1px solid #c6c6c6;
 }
 .accordion-button{
     background-color: unset !important;
     padding: 0.5rem 0.25rem;
     font-size: 0.9rem;
     color: #222222 !important;
     /*
     border: 0 !important;
     border-bottom: 1px solid #EEEEEE;
      */
 }
 .accordion-button:hover{
     color: #C59535;
     background-color: #F8F7F3;
 }
 .accordion-button:focus{
     box-shadow: unset !important;
 }
 .accordion-button:not(.collapsed) {
     color: #222222 !important;
     background-color: unset !important;
     box-shadow: unset !important;
 }
 .accordion-button::after {
     font-family: "FontAwesome";
     content: "\F106";
     width: unset !important;
     font-size: 1rem;
     font-weight: 700;
     position: absolute;
     right: 15px;
     top: 5px;
     color: #222222;
     transition: all 0.2s ease-in-out;
     background-image: unset !important;
 }
 .accordion-button:not(.collapsed)::after {
 }
